import {
    ChangeDetectorRef,
    Component,
    HostListener,
    OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    currentLang: string;
    isDropdownOpen = false;
    isMobile: boolean = window.innerWidth < 991;

    constructor(private translate: TranslateService) {
        const savedLang = localStorage.getItem('userLang');
        this.currentLang = savedLang
            ? savedLang
            : this.translate.getDefaultLang();

        this.translate.use(this.currentLang);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobile = window.innerWidth < 991;
    }

    ngOnInit(): void {
        this.translate.onLangChange.subscribe((langChangeEvent) => {
            this.currentLang = langChangeEvent.lang;
        });
    }

    switchLanguage(language: string): void {
        this.translate.use(language);
        this.currentLang = language;
        localStorage.setItem('userLang', language);
    }
}
