<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>{{ "general.services" | translate }}</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Single Service Area -->
<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text">
                    <div class="service-image">
                        <img
                            src="assets/img/services/services_1.jpg"
                            alt="image"
                        />
                    </div>
                    <h3>
                        {{ "servicesPage.services.savingsTitle" | translate }}
                    </h3>
                    <p>
                        {{
                            "servicesPage.serviceDescriptions.savingsDes"
                                | translate
                        }}
                    </p>
                    <h3>
                        {{ "servicesPage.services.timeTitle" | translate }}
                    </h3>
                    <p>
                        {{
                            "servicesPage.serviceDescriptions.timeDes"
                                | translate
                        }}
                    </p>
                    <h3>
                        {{ "servicesPage.services.routesTitle" | translate }}
                    </h3>
                    <p>
                        {{
                            "servicesPage.serviceDescriptions.routesDes"
                                | translate
                        }}
                    </p>
                    <h3>
                        {{ "servicesPage.services.trackingTitle" | translate }}
                    </h3>

                    <p>
                        {{
                            "servicesPage.serviceDescriptions.trackingDes"
                                | translate
                        }}
                    </p>
                    <h3>
                        {{
                            "servicesPage.services.reliabilityTitle" | translate
                        }}
                    </h3>

                    <p>
                        {{
                            "servicesPage.serviceDescriptions.reliabilityDes"
                                | translate
                        }}
                    </p>
                    <h3>
                        {{ "servicesPage.services.safteyTitle" | translate }}
                    </h3>

                    <p>
                        {{
                            "servicesPage.serviceDescriptions.safteyDes"
                                | translate
                        }}
                    </p>
                    <h3>
                        {{ "servicesPage.services.solutionsTitle" | translate }}
                    </h3>

                    <p>
                        {{
                            "servicesPage.serviceDescriptions.solutionsDes"
                                | translate
                        }}
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">
                        {{ "servicesPage.title" | translate }}
                    </h3>

                    <ul>
                        <li>
                            <a routerLink="/services-details"
                                ><i class="bx bx-wallet"></i>
                                {{
                                    "servicesPage.services.savingsTitle"
                                        | translate
                                }}</a
                            >
                        </li>
                        <li>
                            <a routerLink="/services-details"
                                ><i class="bx bx-time"></i
                                >{{
                                    "servicesPage.services.timeTitle"
                                        | translate
                                }}</a
                            >
                        </li>
                        <li>
                            <a routerLink="/services-details"
                                ><i class="bx bxs-truck"></i
                                >{{
                                    "servicesPage.services.routesTitle"
                                        | translate
                                }}</a
                            >
                        </li>
                        <li>
                            <a routerLink="/services-details"
                                ><i class="bx bx-transfer"></i>
                                {{
                                    "servicesPage.services.customerTitle"
                                        | translate
                                }}</a
                            >
                        </li>
                        <li>
                            <a routerLink="/services-details"
                                ><i class="bx bx-search"></i>
                                {{
                                    "servicesPage.services.trackingTitle"
                                        | translate
                                }}</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Single Service Area -->
