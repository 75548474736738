<!-- Footer Area-->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <div class="logo">
                        <img src="assets/img/newlogo.png" alt="logo" />
                    </div>
                    <ul class="footer-socials">
                        <li>
                            <a
                                href="https://www.facebook.com/profile.php?id=61554977163466&locale=lv_LV"
                                target="_blank"
                                ><i class="bx bxl-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/iecavnieks_auto_logistics/"
                                target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-80">
                    <h3>{{ "general.company" | translate }}</h3>

                    <ul class="footer-text">
                        <li>
                            <a routerLink="/about">{{
                                "general.about" | translate
                            }}</a>
                        </li>
                        <li>
                            <a routerLink="/">{{
                                "general.gallery" | translate
                            }}</a>
                        </li>
                        <li>
                            <a routerLink="/services">{{
                                "general.services" | translate
                            }}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-50">
                    <h3>{{ "general.support" | translate }}</h3>

                    <ul class="footer-text">
                        <li>
                            <a routerLink="/contact">{{
                                "general.contact" | translate
                            }}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <h3>{{ "general.contactInfo" | translate }}</h3>

                    <ul class="info-list">
                        <li>
                            <i class="bx bxs-location-plus"></i> Bauskas nov.,
                            Iecavas pag., "Iecavnieki", LV-3913; Reģ. Nr.
                            43603043473
                        </li>
                        <li>
                            <i class="bx bxs-envelope"></i>
                            <a href="mailto:logistics&#64;iecavnieksauto.lv"
                                >logistics&#64;iecavnieksauto.lv</a
                            >
                        </li>
                        <li>
                            <i class="bx bxs-phone"></i>
                            <a href="tel:+37126145712">+371 26145712</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->
