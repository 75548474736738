<!-- Header Area -->
<header class="header-area">
    <div class="navbar-area">
        <div class="floza-responsive-nav">
            <div class="container">
                <div class="floza-responsive-menu">
                    <div class="logo">
                        <a routerLink="/"
                            ><img src="assets/img/newlogo.png" alt="logo"
                        /></a>
                    </div>
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="langMenu"
                        *ngIf="isMobile"
                    >
                        <mat-icon>language</mat-icon>
                    </button>
                    <mat-menu #langMenu="matMenu">
                        <button mat-menu-item (click)="switchLanguage('en')">
                            EN
                        </button>
                        <button mat-menu-item (click)="switchLanguage('lv')">
                            LV
                        </button>
                        <button mat-menu-item (click)="switchLanguage('ru')">
                            RU
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>

        <div class="floza-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/"
                        ><img src="assets/img/newlogo.png" alt="logo"
                    /></a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav ms-auto">
                            <li class="nav-item">
                                <a
                                    routerLink="/"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{
                                        exact: true
                                    }"
                                    >{{ "general.home" | translate }}</a
                                >
                            </li>

                            <li class="nav-item">
                                <a
                                    routerLink="/about"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{ "general.about" | translate }}</a
                                >
                            </li>

                            <li class="nav-item">
                                <a
                                    routerLink="/gallery"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{
                                        exact: true
                                    }"
                                    >{{ "general.gallery" | translate }}</a
                                >
                            </li>

                            <li class="nav-item">
                                <a
                                    routerLink="/services-details"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{
                                        exact: true
                                    }"
                                    >{{ "general.services" | translate }}</a
                                >
                            </li>

                            <li class="nav-item">
                                <a
                                    routerLink="/contact"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{ "general.contact" | translate }}</a
                                >
                            </li>

                            <li class="nav-item" *ngIf="!isMobile">
                                <a href="javascript:void(0)" class="nav-link"
                                    >{{ currentLang | uppercase }}
                                    <i class="bx bx-chevron-down"></i
                                ></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            (click)="switchLanguage('en')"
                                            >EN</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            (click)="switchLanguage('lv')"
                                            >LV</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            (click)="switchLanguage('ru')"
                                            >RU</a
                                        >
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- End Header Area -->
