<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>{{ "general.about" | translate }}</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- About Safe Area -->
<div class="about-text-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-safe-text">
                    <h2>{{ "homePage.aboutTitle" | translate }}</h2>
                    <p>
                        {{ "aboutPage.content" | translate }}
                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="safe-image">
                    <img
                        src="assets/img/speciality/snow_about.jpg"
                        alt="image"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Safe Area -->

<!-- partner Slider Area -->
<div class="partner-area pb-100">
    <div class="container">
        <div class="section-title">
            <span>{{ "homePage.partnerSmall" | translate }}</span>
            <h2>{{ "homePage.partnerTitle" | translate }}</h2>
        </div>

        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-slider-item">
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/tankpool.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/eurowag.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/scania.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/adero.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/dkv.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/dtk.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="https://www.balticovo.lv/lv" target="_blank"
                    ><img src="assets/img/partner/balticovo.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="http://logifresh.be/en/home-2/" target="_blank"
                    ><img src="assets/img/partner/logifresh.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a
                    href="https://www.facebook.com/IecavnieksAutoServiss/"
                    target="_blank"
                    ><img src="assets/img/partner/iecav_serviss.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/iecav_co.png" alt="logo"
                /></a>
            </div>
        </div>
    </div>
</div>
<!-- End partner Slider Area -->
