<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>{{ "general.gallery" | translate }}</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Gallery Area -->
<div class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery_1.jpg" alt="image" />
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery_1.jpg"
                                    ><i class="bx bx-show-alt"></i
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery_2.jpg" alt="image" />
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery_2.jpg"
                                    ><i class="bx bx-show-alt"></i
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery_3.jpg" alt="image" />
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery_3.jpg"
                                    ><i class="bx bx-show-alt"></i
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery_4.jpg" alt="image" />
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery_4.jpg"
                                    ><i class="bx bx-show-alt"></i
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery_5.jpg" alt="image" />
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery_5.jpg"
                                    ><i class="bx bx-show-alt"></i
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery_6.jpg" alt="image" />
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery_6.jpg"
                                    ><i class="bx bx-show-alt"></i
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery_7.jpg" alt="image" />
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery_7.jpg"
                                    ><i class="bx bx-show-alt"></i
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery_8.jpg" alt="image" />
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery_8.jpg"
                                    ><i class="bx bx-show-alt"></i
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Gallery Area -->
