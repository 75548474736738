import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';

const routes: Routes = [
    { path: '', component: HomeOneComponent },
    { path: 'about', component: AboutComponent },
    { path: 'gallery', component: GalleryComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'services-details', component: ServicesDetailsComponent },
    { path: 'contact', component: ContactComponent },

    // Here add new component

    { path: '**', component: ErrorComponent }, // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
