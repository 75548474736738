<!-- Hero Slider -->
<div class="hero-slider owl-carousel owl-theme">
    <div class="hero-slider-item item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <h1>{{ "homePage.welcome" | translate }}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Hero Slider -->

<!-- Contact Area -->
<div class="container">
    <div class="contact-area mb-85">
        <div class="contact-content">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class="bx bxs-phone"></i>
                        <h4>{{ "general.contactNumber" | translate }}</h4>
                        <p><a href="tel:+37128733252">+371 28733252</a></p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class="bx bxs-location-plus"></i>
                        <h4>{{ "general.location" | translate }}</h4>
                        <p>Bauskas nov., Iecavas pag.,</p>
                        <p>"Iecavnieki", LV-3913</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class="bx bx-show"></i>
                        <h4>{{ "general.workingHours" | translate }}</h4>
                        <p>{{ "general.workTime" | translate }}</p>
                        <p>{{ "general.workDays" | translate }}</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class="bx bxs-envelope"></i>
                        <h4>{{ "general.email" | translate }}</h4>
                        <p>
                            <a href="mailto:logistics&#64;iecavnieksauto.lv"
                                >logistics&#64;iecavnieksauto.lv</a
                            >
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Contact Area -->

<!-- About Area -->
<div class="about-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="about-contant">
                    <div class="section-title">
                        <span>{{ "general.about" | translate }}</span>
                        <h2>{{ "homePage.aboutTitle" | translate }}</h2>
                    </div>
                    <div class="about-text">
                        <p>
                            {{ "homePage.aboutDescription" | translate }}
                        </p>
                        <a routerLink="/about" class="default-btn-one btn-bs">{{
                            "general.learnMore" | translate
                        }}</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/about1.jpg" alt="about-image" />
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Area -->

<!-- Services Area -->
<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>{{ "servicesPage.title" | translate }}</span>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class="bx bx-wallet"></i>
                    <h3>
                        {{ "servicesPage.services.savingsTitle" | translate }}
                    </h3>
                    <p>
                        {{
                            "servicesPage.serviceDescriptions.savingsDes"
                                | translate
                        }}
                    </p>
                    <a routerLink="/services-details" class="default-btn-two">{{
                        "general.learnMore" | translate
                    }}</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class="bx bx-time"></i>
                    <h3>
                        {{ "servicesPage.services.timeTitle" | translate }}
                    </h3>
                    <p>
                        {{
                            "servicesPage.serviceDescriptions.timeDes"
                                | translate
                        }}
                    </p>
                    <a routerLink="/services-details" class="default-btn-two">{{
                        "general.learnMore" | translate
                    }}</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class="bx bxs-truck"></i>
                    <h3>
                        {{ "servicesPage.services.routesTitle" | translate }}
                    </h3>
                    <p>
                        {{
                            "servicesPage.serviceDescriptions.routesDes"
                                | translate
                        }}
                    </p>
                    <a routerLink="/services-details" class="default-btn-two">{{
                        "general.learnMore" | translate
                    }}</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class="bx bx-transfer"></i>
                    <h3>
                        {{ "servicesPage.services.customerTitle" | translate }}
                    </h3>
                    <p>
                        {{
                            "servicesPage.serviceDescriptions.customerDes"
                                | translate
                        }}
                    </p>
                    <a routerLink="/services-details" class="default-btn-two">{{
                        "general.learnMore" | translate
                    }}</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Partner Area -->
<div class="partner-area pb-100">
    <div class="container">
        <div class="section-title">
            <span>{{ "homePage.partnerSmall" | translate }}</span>
            <h2>{{ "homePage.partnerTitle" | translate }}</h2>
        </div>

        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-slider-item">
                <a href="https://www.tankpool24.eu/en/home" target="_blank"
                    ><img src="assets/img/partner/tankpool.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="https://www.eurowag.com/" target="_blank"
                    ><img src="assets/img/partner/eurowag.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="https://www.scania.com/lv/lv/home.html" target="_blank"
                    ><img src="assets/img/partner/scania.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="https://adero.lv/lv/" target="_blank"
                    ><img src="assets/img/partner/adero.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="https://www.dkv-mobility.com/en/" target="_blank"
                    ><img src="assets/img/partner/dkv.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/dtk.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="https://www.balticovo.lv/lv" target="_blank"
                    ><img src="assets/img/partner/balticovo.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="http://logifresh.be/en/home-2/" target="_blank"
                    ><img src="assets/img/partner/logifresh.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a
                    href="https://www.facebook.com/IecavnieksAutoServiss/"
                    target="_blank"
                    ><img src="assets/img/partner/iecav_serviss.png" alt="logo"
                /></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/iecav_co.png" alt="logo"
                /></a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->
