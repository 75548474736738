<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>{{ "contactPage.title" | translate }}</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Conatct Info -->
<div class="pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info">
                    <i class="bx bxs-phone"></i>
                    <h4>{{ "general.contactNumber" | translate }}</h4>
                    <p><a href="tel:+37128733252">+371 28733252</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info">
                    <i class="bx bxs-location-plus"></i>
                    <h4>{{ "general.location" | translate }}</h4>
                    <p>Bauskas nov., Iecavas pag.,</p>
                    <p>"Iecavnieki", LV-3913</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-info">
                    <i class="bx bxs-envelope"></i>
                    <h4>{{ "general.email" | translate }}</h4>
                    <p>
                        <a href="mailto:logistics&#64;iecavnieksauto.lv"
                            >logistics&#64;iecavnieksauto.lv</a
                        >
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Conatct Info -->
